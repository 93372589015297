import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { SlotsStatus } from '../../../../../types/types';
import { SlotsPerDay } from '../../../ViewModel/weeklyLayoutViewModel/weeklyLayoutViewModel';
import { NoAvailableSlotsViewModel } from '../../../ViewModel/noAvailableSlotsViewModel/noAvailableSlotsViewModel';
import { SlotsSelectionViewModel } from '../../../ViewModel/slotsSelectionViewModel/slotsSelectionViewModel';
import { Spinner, SpinnerTypes, Text } from 'wix-ui-tpa';
import { st, classes } from './WeekAvailability.st.css';
import { DataHooks } from './constants';
import { SlotsSelectionDisplayMode } from '../../SlotsSelection/constants';
import SlotsSelection from '../../SlotsSelection/SlotsSelection';
import NoAvailableSlots from '../../NoAvailableSlots/NoAvailableSlots';
import { SrOnly } from '../../../../../utils/accessibility/SrOnly/SrOnly';

export interface WeekAvailabilityProps {
  slotsStatus: SlotsStatus;
  slotsPerDays: SlotsPerDay[];
  noAvailableSlotsViewModel: NoAvailableSlotsViewModel;
}

const WeekAvailability: React.FC<WeekAvailabilityProps> = ({
  slotsPerDays,
  slotsStatus,
  noAvailableSlotsViewModel,
}) => {
  const { isEditor } = useEnvironment();

  return (
    <div className={classes.root} data-hook={DataHooks.WeekAvailability}>
      <div className={classes.week}>
        {slotsPerDays?.map((slotsPerDay) => {
          const {
            date,
            slotsSelectionViewModel,
            isToday,
            isPastDate,
            accessibility,
          } = slotsPerDay;
          return (
            <div
              className={st(classes.dayWrapper, {
                isToday: !isEditor && isToday,
                isPastDate: !isEditor && isPastDate,
              })}
              data-hook={`${DataHooks.DayAvailability}-${date}`}
              role="group"
              aria-label={accessibility.dayDetailsAriaLabel}
              key={date}
            >
              <DayDetails slotsPerDay={slotsPerDay} />
              {slotsStatus === SlotsStatus.AVAILABLE_SLOTS ? (
                <Slots
                  date={date}
                  slotsSelectionViewModel={slotsSelectionViewModel}
                  accessibility={accessibility}
                />
              ) : null}
            </div>
          );
        })}
      </div>
      {slotsStatus === SlotsStatus.NO_AVAILABLE_SLOTS ? (
        <NoAvailableSlots viewModel={noAvailableSlotsViewModel!} />
      ) : null}
      {slotsStatus === SlotsStatus.LOADING ? (
        <div className={classes.spinner}>
          <Spinner
            data-hook={DataHooks.Loader}
            diameter={24}
            isCentered={true}
            type={SpinnerTypes.slim}
          />
        </div>
      ) : null}
    </div>
  );
};

interface DayDetailsProps {
  slotsPerDay: SlotsPerDay;
}

const DayDetails: React.FC<DayDetailsProps> = ({ slotsPerDay }) => {
  const { weekday, day } = slotsPerDay;

  return (
    <div
      aria-hidden="true"
      className={classes.dayDetails}
      data-hook={DataHooks.DayDetails}
    >
      <Text className={classes.weekday}>{weekday}</Text>
      <Text className={classes.day}>{day}</Text>
    </div>
  );
};

interface SlotsProps {
  date: string;
  slotsSelectionViewModel: SlotsSelectionViewModel;
  accessibility: {
    dayWithoutSlotsSrOnlyText: string;
    dayWithSlotsSrOnlyText: string;
  };
}

const Slots: React.FC<SlotsProps> = ({
  date,
  slotsSelectionViewModel,
  accessibility: { dayWithoutSlotsSrOnlyText, dayWithSlotsSrOnlyText },
}) => {
  if (slotsSelectionViewModel.timeSlots.length) {
    return (
      <>
        <SrOnly data-hook={`${DataHooks.DayWithSlotsSrOnly}-${date}`}>
          {dayWithSlotsSrOnlyText}
        </SrOnly>
        <SlotsSelection
          date={date}
          viewModel={slotsSelectionViewModel}
          displayMode={SlotsSelectionDisplayMode.Column}
        />
      </>
    );
  }
  return (
    <>
      <SrOnly data-hook={`${DataHooks.DayWithoutSlotsSrOnly}-${date}`}>
        {dayWithoutSlotsSrOnlyText}
      </SrOnly>
      <Text
        key={`${DataHooks.DayWithoutSlots}-${date}`}
        data-hook={`${DataHooks.DayWithoutSlots}-${date}`}
        className={classes.noSlots}
        aria-hidden={true}
      >
        -
      </Text>
    </>
  );
};

export default WeekAvailability;
