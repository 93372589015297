import React from 'react';
import { st, classes } from './NoAvailableSlots.st.css';
import { AccessibilityHtmlTags } from '../../../../utils/accessibility/constants';

import {
  Text,
  SectionNotificationType as NOTIFICATION_TYPE,
  SectionNotification,
  TextButton,
} from 'wix-ui-tpa';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import { NoAvailableSlotsViewModel } from '../../ViewModel/noAvailableSlotsViewModel/noAvailableSlotsViewModel';
import { DataHooks } from './constants';
import { useCalendarActions } from '../../Hooks/useCalendarActions';
import { ReactComponent as AlertIcon } from '../../../../assets/Icons/notificationAlert.svg';

export type NoAvailableSlotsProps = {
  viewModel: NoAvailableSlotsViewModel;
};

const NoAvailableSlots: React.FC<NoAvailableSlotsProps> = ({ viewModel }) => {
  const { isMobile } = useEnvironment();
  const {
    noSessionsOfferedText,
    goToNextAvailableLinkText,
    noUpcomingTimeSlotsText,
  } = viewModel;
  const { goToNextAvailableDate } = useCalendarActions();

  return (
    <div
      className={st(classes.root, { isMobile })}
      data-hook={DataHooks.NoAvailableSlots}
    >
      <Text
        data-hook={DataHooks.Text}
        className={st(classes.noAvailableSlots)}
        tagName={AccessibilityHtmlTags.Paragraph}
      >
        {noSessionsOfferedText}
      </Text>
      {goToNextAvailableLinkText ? (
        <TextButton
          data-hook={DataHooks.Link}
          className={classes.nextAvailableDateLink}
          onClick={() => goToNextAvailableDate()}
        >
          {goToNextAvailableLinkText}
        </TextButton>
      ) : null}
      {noUpcomingTimeSlotsText ? (
        <SectionNotification
          className={classes.notification}
          data-hook={DataHooks.Notification}
          type={NOTIFICATION_TYPE.alert}
        >
          <SectionNotification.Icon icon={<AlertIcon />} />
          <SectionNotification.Text>
            {noUpcomingTimeSlotsText}
          </SectionNotification.Text>
        </SectionNotification>
      ) : null}
    </div>
  );
};

export default NoAvailableSlots;
