import { classes } from './Sidebar.st.css';
import React from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';
import BookingDetails from '../BookingDetails/BookingDetails';
import { SidebarViewModel } from '../../ViewModel/sidebarViewModel/sidebarViewModel';
import { FlowElements, useFlow } from '../../Hooks/useFlow';
import Login from '../Login/Login';
import { useExperiments } from '@wix/yoshi-flow-editor';

export interface SidebarProps {
  viewModel: SidebarViewModel;
}

const Sidebar: React.FC<SidebarProps> = ({
  viewModel: { sidebarTitle, bookingDetailsViewModel, loginViewModel },
}) => {
  const sidebarRef = useFlow(FlowElements.SIDEBAR);
  const { experiments } = useExperiments();

  const isShowPricingPlanEndDateIndicationEnabled = experiments.enabled(
    'specs.bookings.ShowPricingPlanEndDateIndication',
  );

  return (
    <div ref={sidebarRef} data-hook="sidebar" className={classes.root}>
      <SectionHeader title={sidebarTitle} />
      <BookingDetails viewModel={bookingDetailsViewModel} />
      {isShowPricingPlanEndDateIndicationEnabled ? (
        <Login viewModel={loginViewModel} />
      ) : null}
    </div>
  );
};

export default Sidebar;
